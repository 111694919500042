<!--
 * @Description: 人才首页
 * @Author: huacong
 * @Date: 2021-03-29 11:35:26
 * @LastEditTime: 2021-07-19 17:40:11
 * @LastEditors: huacong
-->
<template>
  <div class="talentBg">
    <section>
      <el-row class="talentIndex" :gutter="16">
        <el-col :span="8">
          <talentInfo></talentInfo>
        </el-col>
        <el-col :span="16">
          <el-button
            type="text"
            v-prevent-repeat-click
            @click="goPartTimme"
            class="moreWork"
          >
            查看更多职位 >>
          </el-button>
          <el-tabs v-model="activeName">
            <el-tab-pane label="猎头职位推荐" name="first">
              <el-row :gutter="20">
                <el-col :span="12" v-for="item in partData" :key="item.id">
                  <div class="workBox">
                    <div class="bothEnd cerName">
                      <span
                        class="font-blod fontHover"
                        @click="gopartTime(item.id)"
                      >
                        {{ item.j_certificate }}-{{ item.j_major }}
                      </span>
                      <span>
                        {{ item.create_time }}
                      </span>
                    </div>
                    <div class="cerInfo">
                      <span
                        class="font-orange"
                        style="font-size: 16px;margin-right: 8px"
                      >
                        {{ item.j_min_price }}万-{{ item.j_max_price }}万
                      </span>
                      <span>
                        {{ item.j_area_province }}-{{ item.j_area_city }}
                      </span>
                    </div>
                    <div class="bothEnd sendCV">
                      <span style="font-weight: bold;">
                        {{ item.j_certificate }}-{{ item.j_major }}
                        <img class="mini-icon" :src="cerImg" />
                      </span>
                      <el-button
                        v-prevent-repeat-click
                        size="mini"
                        type="primary"
                        @click="deliver(item)"
                      >
                        投递
                      </el-button>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </section>
  </div>
</template>
<script>
import talentInfo from "@/view/talentPage/talentInfo.vue";
export default {
  components: {
    talentInfo,
  },
  data() {
    return {
      cerImg: require("@/assets/icon/cer.png"),
      activeName: "first",
      partData: [],
    };
  },
  methods: {
    getPartTime() {
      this.$http
        .post("index/parjob/getlist", { page: 1, limit: 10 })
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.partData = res.data.resultData.list;
          }
        });
    },
    // 兼职投递简历
    deliver(workInfo) {
      this.$http
        .post("index/parjob/jobdetail", { id: workInfo.id })
        .then((res) => {
          if (res.data.resultCode == 1) {
            let jobInfo = res.data.resultData.job;
            // 投递信息
            let info = {
              publish_uid: jobInfo.j_uid,
              com_type: jobInfo.j_type,
              com_id: jobInfo.j_company_id,
              position_id: jobInfo.id,
              position_name: jobInfo.j_certificate,
              position_type: 1, // 兼职
            };
            this.$http.post("talent/index/delivery", info).then((res) => {
              if (res.data.resultCode == 1) {
                this.$notify({
                  type: "success",
                  title: "投递成功",
                  message: "您已投递成功，请耐心等待招聘人员与您联系！",
                });
              }
            });
          }
        });
    },
    // 跳转兼职
    goPartTimme() {
      this.$router.push({ path: "/Home/partTimeIndex" });
    },
    // 兼职简历详情
    gopartTime(id) {
      this.$router.push({
        name: "partTimeInfo",
        query: { id: id },
      });
    },
  },
  mounted() {
    this.getPartTime();
  },
};
</script>
<style scoped>
.talentIndex {
  padding-top: 24px;
}
.workBox {
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 20px;
}
.workBox .cerName {
  padding: 20px 20px 3px;
}
.workBox .cerInfo {
  padding: 3px 20px 20px;
}
.workBox .sendCV {
  padding: 10px 20px;
  border-top: 1px solid #e8e8e8;
}
.bothEnd {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.moreWork {
  position: absolute;
  top: 25px;
  right: 20px;
  cursor: pointer;
  z-index: 99;
}
</style>
